@import url('https://fonts.googleapis.com/css?family=Merriweather:300i');

.App {
  font-family: 'Merriweather', serif;
  font-style: italic;
  padding: 0px 50px;
  line-height: 2em;
  font-size: 1.2em;
}

.title {
  font-size: 0.6em;
  font-weight: bold;
}

.menu {
  margin-bottom: 30px;
}

.rules__query {
  width: 100%;
  padding: 0.5em 0.3em;
  font-size: 1.5em;
  border: 0px;
  border-bottom: solid 1px black;
}

.rules__query:focus {
  outline: none;
  background-color: #f5f5f5;
}

.m-0 { margin: 0px; }

.m-bottom-0 { margin-bottom: 0px; }
.m-bottom-3 { margin-bottom: 9px; }
.m-bottom-4 { margin-bottom: 12px; }
.m-bottom-6 { margin-bottom: 18px; }

.m-right-2 { margin-right: 6px;  }
.m-right-3 { margin-right: 9px;  }
.m-right-4 { margin-right: 12px; }